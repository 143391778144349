import React from 'react';
import styled from '@emotion/styled';

import AppQRCodeIcon from 'assets/pngs/app_qr_code.png';
import AfterPurchaseComponent from 'navigation/AfterPurchaseComponent';
import GooglePlayIcon from 'assets/pngs/google_play.png';
import AppStoreIcon from 'assets/pngs/apple_store.png';
import Text from 'components/Text';
import FullScreenWrapper from 'components/FullScreenWrapper';
import ExternalLink from 'components/ExternalLink';
import { EXTERNAL_LINKS } from 'utils/constants';
import { useUserInfo } from 'state/UserInfoStateProvider';

const Title = styled(Text)({
  fontSize: 27,
  lineHeight: '41px',
  marginBottom: 20,
  fontWeight: 700,
  textAlign: 'center',
});

const Description = styled(Text)({
  opacity: 0.8,
  textAlign: 'center',
  fontSize: 12,
  lineHeight: '18px',
  marginBottom: 12,
});

const Email = styled(Text)({
  textAlign: 'center',
  fontSize: 16,
  lineHeight: '24px',
  padding: '0 4px',
  marginTop: 5,
  maxWidth: 432,
  wordBreak: 'break-word',
});

const Divider = styled(Text)({
  width: '100%',
  display: 'grid',
  gridAutoFlow: 'column',
  gridTemplateColumns: '1fr min-content 1fr',
  gridColumnGap: 9.5,
  alignItems: 'center',
  fontSize: 12,
  lineHeight: '18px',
  marginTop: 18,
  ':before, :after': {
    content: '""',
    display: 'block',
    height: 1,
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
  },
});

const ScanAppText = styled(Description)({
  marginBottom: 19,
  ':before': {
    content: '""',
    display: 'block',
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    margin: '30px 0',
  },
});

const QRCode = styled.img({
  width: 125,
  height: 125,
  justifySelf: 'center',
});

const Links = styled.div({
  display: 'flex',
  flexWrap: 'wrap',
  justifyContent: 'center',
  marginTop: 29,
  gap: 4,
  padding: '0 4px',
  ':after': {
    content: '""',
    display: 'block',
    height: 1,
    width: '100%',
    backgroundColor: 'rgba(255, 255, 255, 0.1)',
    margin: '52px 0 19px',
  },
});

const LinkContainer = styled.a({
  justifyContent: 'center',
  display: 'flex',
  alignItems: 'center',
  textDecoration: 'none',
});

const StoreIcon = styled.img({
  width: 148,
  height: '100%',
});

const AccountManagementText = styled(Description)({
  textAlign: 'center',
  opacity: 0.8,
  marginBottom: 4,
});

const Container = styled(FullScreenWrapper)({
  display: 'grid',
  alignContent: 'center',
  justifyContent: 'center',
});

const Link = styled(ExternalLink)({
  fontSize: 12,
  lineHeight: '18px',
  textAlign: 'center',
});

const PurchaseSuccess: React.FC = () => {
  const user = useUserInfo();

  return (
    <AfterPurchaseComponent>
      <Container>
        <Title>Welcome to YoursApp!</Title>
        <Description>Your login email:</Description>
        <Email>{user?.info?.user?.email}</Email>
        <ScanAppText>Scan with your mobile device to get the app:</ScanAppText>
        <LinkContainer href={EXTERNAL_LINKS.GET_APP} target="_blank">
          <QRCode src={AppQRCodeIcon} />
        </LinkContainer>
        <Divider>OR</Divider>
        <Links>
          <LinkContainer href={EXTERNAL_LINKS.APP_STORE} target="_blank">
            <StoreIcon src={AppStoreIcon} />
          </LinkContainer>
          <LinkContainer href={EXTERNAL_LINKS.GOOGLE_PLAY} target="_blank">
            <StoreIcon src={GooglePlayIcon} />
          </LinkContainer>
        </Links>
        <AccountManagementText>You can manage your account in: </AccountManagementText>
        <Link href={EXTERNAL_LINKS.WEB_APP} target="_blank" rel="noreferrer">
          Our web application
        </Link>
      </Container>
    </AfterPurchaseComponent>
  );
};

export default PurchaseSuccess;
